import React from "react"
import CaseStudyStyle from "./styles/CaseStudyStyle.module.styl"
import { Link } from "gatsby"

const CaseStudyWorkSection = ({ text, subText, next }) => {
  return (
    <section className={CaseStudyStyle.case_study_work_section}>
      <div className={CaseStudyStyle.case_study_work_section_description}>
        <h3 className={CaseStudyStyle.case_study_work_section_text}>{text}</h3>
        {subText && <h3 className={CaseStudyStyle.sub_text}>{subText}</h3>}
        <div className={CaseStudyStyle.case_study_buttons_holder}>
          <Link to="/apply">
            Apply Now
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5.848"
              height="9.647"
              viewBox="0 0 5.848 9.647"
            >
              <path
                className="a"
                d="M4.817,3.5,1.179,0,0,1.229,4.817,5.848l4.83-4.618L8.467,0Z"
                transform="translate(0 9.647) rotate(-90)"
              />
            </svg>
          </Link>
          <Link className={CaseStudyStyle.next_case_study} to={next}>
            Next Case Study
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5.848"
              height="9.647"
              viewBox="0 0 5.848 9.647"
            >
              <path
                className="a"
                d="M4.817,3.5,1.179,0,0,1.229,4.817,5.848l4.83-4.618L8.467,0Z"
                transform="translate(0 9.647) rotate(-90)"
              />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyWorkSection
