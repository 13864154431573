import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyPlanetHomeTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Planet Home Festival"
        description="Putting Hook Points to work to help make social change and save the planet"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Helped a brand new festival elevate its brand and{" "}
              <span>achieve success while provoking awareness</span> for an
              important cause
            </li>
            <li>
              Our strategic Hook Points and{" "}
              <span>marketing strategy drove ticket sales</span> to the
              festival’s inaugural event, featuring talent such as Snoop Dog,
              Wyclef Jean, billionaire Peter Diamandis, and Bill Nye the Science
              Guy
            </li>
            <li>
              The Hooks were so effective, that we <span>sold out</span> and hit
              capacity for ticket sales
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-craig-clemens"}
        text="That’s not all that we can tell you about how we served Planet Home.  "
        subText="Apply to work with us and learn more"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyPlanetHomeTemplate
