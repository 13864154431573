import React, { useState } from "react"
import CaseStudyStyle from "./styles/CaseStudyStyle.module.styl"
import Image from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import SplitText from "react-pose-text"
import Cross from "../../../assets/images/cross.svg"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}
function CaseStudyHeaderSection({ image, title, description, smallImage }) {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <VisibilitySensor
      minTopValue={200}
      partialVisibility={true}
      onChange={onChange}
    >
      <section className={CaseStudyStyle.one_million_followers_section}>
        <div className={CaseStudyStyle.description}>
          <div className={CaseStudyStyle.case_study}>
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Case Study
            </SplitText>
            <span />
          </div>
          <h2 className={CaseStudyStyle.title}>{title}</h2>
          <p className={CaseStudyStyle.description_text}>{description}</p>
        </div>
        <div
          className={
            smallImage
              ? CaseStudyStyle.small_image_holder
              : CaseStudyStyle.image_holder
          }
        >
          {" "}
          <Image
            className={
              smallImage ? CaseStudyStyle.small_image : CaseStudyStyle.image
            }
            fluid={image[0].node.childImageSharp.fluid}
          />
        </div>
        <img className={CaseStudyStyle.cross} src={Cross} alt="cross" />
      </section>
    </VisibilitySensor>
  )
}

export default CaseStudyHeaderSection
