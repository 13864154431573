import React from "react"
import CaseStudyStyle from "./styles/CaseStudyStyle.module.styl"

const CaseStudyDescriptionSection = ({ title, list, moreInfo }) => {
  return (
    <section className={CaseStudyStyle.case_study_description_section}>
      {title && (
        <div className={CaseStudyStyle.case_study_description_title_holder}>
          {title}
        </div>
      )}

      <div className={CaseStudyStyle.case_study_description_list}>{list}</div>
      {moreInfo && (
        <div className={CaseStudyStyle.case_study_description_title_holder}>
          {moreInfo}
        </div>
      )}
    </section>
  )
}

export default CaseStudyDescriptionSection
